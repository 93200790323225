(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name neo.services.auth.factory:ResetPassword
   *
   * @description
   *
   */
  angular
  .module('neo.services.auth')
  .factory('ResetPassword', ResetPassword);

  function ResetPassword($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/auth/resetPassword');
  }
}());
